$error: #EE2839;

.page-documents {
    padding-left:30px;
    padding-right: 30px;
    //margin-left: 6% !important; // FIX ME
    .documents {
        @extend .px-3, .mt-5;

        &-item {
            @extend .mb-4, .mb-md-0;
            @include make-row();
            width: 100%;

            &-name {
                @extend .d-flex,
                .flex-column,
                .flex-md-row,
                .my-2;
                position: relative;

                &:not(.top) {
                  box-shadow: $shadow-elisa;
                    @extend
                    .py-3;
                    background-color: #ffffff;
                    border-radius: 1.25rem;
                    border-left: 6px solid $error;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    flex:1;
                }

                &.top {
                    @extend .d-none, .d-md-flex;

                    >div {
                        color: $body-color;
                    }
                }

                >div {
                    @extend .mx-3;

                    flex: 1 1 auto;
                    color: transparentize(#000000, .5);

                    &.kpi {
                        flex: 0 0 25%;
                    }

                    &.required {
                        flex: 0 0 15%;
                    }
                }

                @include make-col-ready();

                @include media-breakpoint-up(md) {
                    @include make-col(9);
                }
            }

            &-file {
                min-width: 240px;
                @include make-col-ready();

                @include media-breakpoint-up(md) {
                    @include make-col(3);
                }

                .file-exist {
                    height: 100%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;

                    &.show {
                        display: flex;
                    }

                    .delete-file {
                        color: $error;
                        margin-left: 1rem;
                        cursor: pointer;
                    }
                }

                .file-uploaded {
                    height: 100%;
                    display: none;
                    align-items: center;
                    margin-left: 2%;

                    &.show {
                        display: flex;
                    }

                    .custom-file {
                        &-input {
                            display: none;
                            overflow: hidden;
                        }
                        &-label {
                            cursor: pointer;
                            text-align: center;
                            width: auto;

                            &::after {
                                content: "";

                                background: transparent;
                                border: 0;
                                width: 100%;
                                text-align: center;
                            }

                            &.select {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    &:hover{
                      .custom-file-label{
                        color: white;
                      }

                    }
                }
            }
        }
    }
}
