// Spacing Variables
// Change below variable if the height of the navbar changes
$navbar-base-height: 3.4rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 240px;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 90px;
// Change below variable to change the height of the sticky footer
$sticky-footer-height: 80px;
$green-hover: #1FC5AF;
$red-hover: #C51F35;

$lighter-grey: #565656;

$lightest-grey: #F6F8F8;

$lightest-grey-2: #F1F1F1;

$shadow-elisa: 0px 4px 14px 0px rgba(0, 0, 0, 0.14);

//$shadow-big: 15px 15px 50px rgba(0, 0, 0, 0.08), -10px -10px 50px rgba(0, 0, 0, 0.08), inset 0px 4px 20px rgba(255, 255, 255, 0.15);
$shadow-big: 0 1px 1px rgba(0,0,0,0.01), 0 2px 2px rgba(0,0,0,0.01), 0 4px 4px rgba(0,0,0,0.01), 0 6px 8px rgba(0,0,0,0.01), 0 8px 16px rgba(0,0,0,0.01);

$domain-families : [
  'activity',
  'default',
  'diagnostic',
  'ecology',
  'economy',
  'governance',
  'social'
];

$background-color: #EBEBEB;

$light-black: rgba(0, 0, 0, .67);
