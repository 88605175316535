$color-input: #e9f9f7;
$color-input-light: lighten($color-input, 3);
$select2-height: calc(1.5em + 2rem + 2px);

body {
  --select2-selection-background: #{$color-input-light};
  --select2-selection-border-color: #{$color-input-light};
  --select2-selection-focus-border-color: #{$color-input-light};
  --select2-selection-border-radius: 0.5rem;
  --select2-arrow-color: #495057;

  .form-group {
    .select2-container.select2-container--default {
      .select2-selection.select2-selection--single, .select2-selection.select2-selection--multiple {
        height: #{$select2-height};

        &:hover {
          background-color: #{$color-input};
          border-color: #{$color-input};
        }

        .select2-selection__rendered {
          height: #{$select2-height};
          padding: 1rem #{$input-padding-x-lg};
          line-height: 1.5rem;
        }

      }
    }
  }
}
