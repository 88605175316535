.parameters-page {
  .parameter-cards-container {
    display: flex;
    flex-direction: row;
  }

  .card {
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    margin: 2rem !important;
    width: 30%;
  }

  .card-header {
    background: white;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: row;

    &__title {
      color: rgba(0, 0, 0, .67);;
      flex: 1;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 20px;
      min-width: 120px;
      padding: 1rem;
      text-transform: none !important;
    }

    &__action {
      font-size: 1.5rem;
      padding: 0.5rem;

      a {
        color: black;
        &:hover {
          color: var(--brand-color-primary);
        }
      }
    }
  }

  .card-body {
    .user-parameter-title {
      color: var(--brand-color-primary);
    }

    p a {
      color: black;
      text-decoration: underline;

      &:hover {
        color: var(--brand-color-primary);
      }
    }
  }
}
