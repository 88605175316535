#body-container {
  min-height: 100vh;
  padding-top: calc(#{$navbar-base-height});

  display: flex;
  background-color: $background-color;
}





