.page-suivi{
  max-width:1400px !important;
  $max-height-top: 300px;


  .subcategory{
    .progress{
      height: 0.3rem;
    }
  }

  //progress bar
  .container-title-progress-bar{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .top-container{
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;

    .card-body{
      padding: 30px;
      label{
        position: relative;
        position: relative;
        top: 80px;
        left: 20px;
        font-size: 16px;
      }
    }
    .card{
      margin-bottom: 0px;
    }

    .line-chart-canvas {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .radar-chart-canvas {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .top-container .top-container__chart{
    height: $max-height-top;
    flex: 1 0 auto;
    width: 45%;
    select{
      position: absolute;
      right: 23px;
      top: 20px;
    }
    .btn{
      margin-top: 15px;
      margin-right: 15px;
      font-size: 15px;
    }
  }

  .top-container .top-container__note{
    flex: 1 0 auto;
    min-width: 200px;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    min-height: 200px;
    width: 55%;
    div{
      max-height: $max-height-top;
    }
    .score{
      margin-left: 20px;
      margin-right: 20px;
      font-size: 30px;
      font-weight: 200;
      text-align: center;
    }

    .top-container__note__best-score{
      position: relative;
      top: -80px;
      left: -10px;
      font-weight: 200;
    }

    .score-circle-container{
      width: 120px;
    }
  }

  .top-container label{
    position: absolute;
    top: -35px;
    font-family: Montserrat,sans-serif;
    font-weight: 100;
    color: #000;
    font-size: 22px;
    left: 0px;
  }

  .card-note{
    border-radius: 10px;
    flex: initial;
    height: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .cards-container{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    .cards-container__element{
      width: calc(25% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
    }

    .progress-bar{
      transition: all 1.5s ease;
      width: 0px;
    }
  }

  .container-title-progress-bar{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;

    min-width: 168px;
    &::after{
      margin-bottom: 1px solid $lightest-grey;
    }
  }

  @each $family-name in $domain-families {
    .domain-family-#{$family-name} {
      .container-title-progress-bar {
        .titre-suivi {
          color: var(unquote('--brand-domain-family-' + $family-name));
        }
      }
      .subdomain-header-progress {
        .progress-bar.plain-progress-bar {
          background: var(unquote('--brand-domain-family-' + $family-name));
        }
      }
    }
  }

  @media (max-width:1480px) {
    .cards-container{
      .cards-container__element{
        width: calc(50% - 20px);
      }
    }
  }

  @media (max-width:1010px) {
    .cards-container{
      .cards-container__element{
        width: 100%;
      }
    }
  }

  @media (max-width: 1370px) {
    .top-container{
      flex-direction: column-reverse;
      .top-container__chart{
        width: 100%;
      }
      .top-container__note{
        margin-top: 1.8rem !important;
        width: 100%;
        margin-left: 0px;
        padding-top: 50px;
        padding-bottom: 60px;
        padding-left: 0px;
      }
    }
  }
}
