.navbar,
.sidebar {
  z-index: 50;
}

.logo {
  max-width: 178px;
  max-height: 32px;
}

@media (min-width: 900px) {
  .navbar .logo {
    margin-left: 45px;
  }
}

.navbar-nav {
  .form-inline .input-group {
    width: 100%;
  }

  .nav-item {
    &.active {
      .nav-link {
        color: $white;
      }
    }
    &.dropdown {
      .dropdown-toggle {
        &::after {
          width: 1rem;
          text-align: center;
          float: right;
          vertical-align: 0;
          border: 0;
          font-weight: 900;
          content: '\f105';
          font-family: 'Font Awesome 5 Free';
        }
      }
      &.show {
        .dropdown-toggle::after {
          content: '\f107';
        }
      }
      &.no-arrow {
        .dropdown-toggle::after {
          display: none;
        }
      }
    }
    .nav-link {
      &:focus {
        // remove outline for Safari and Firefox
        outline: none;
      }
      .badge {
        position: absolute;
        margin-left: 0.75rem;
        top: 0.3rem;
        font-weight: 400;
        font-size: 0.5rem;
      }
    }
    .navbar-icon {
      width: 20px;
      height: 20px;
    }

    .navbar-badge {
      border-radius: 5px;
    }

    .bg-lightgreen {
      background-color: #00C7B1;
    }
  }

  @media (min-width: 768px) {
    .form-inline .input-group {
      width: auto;
    }
  }
}

.navbar {
  box-shadow: $shadow-big;
  width: 100vw;
  min-height: $navbar-base-height;

  right: 0;
  top: 0;

  padding-right: 20px;

  box-sizing: border-box;

  position: fixed;

  & > .container-nav {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-right: 50px;
  }

  & > .navbar-brand {
    margin: 0 -10px;
  }
}

@media (max-width: 900px) {
  .navbar {
    height: fit-content;
    padding-right: 0;

    & > * {
      width: fit-content;
      height: fit-content;
      margin: auto;
    }

    & > .navbar-brand {
      margin: 0 20px;

      //margin-bottom: 20px;
      .logo {
        width: 145px;
        height: 40px;
      }
    }
  }
}

.sidebar {
  width: $sidebar-collapsed-width !important;
  background-color: $gray-900;
  min-height: calc(100vh - #{$navbar-base-height});
  @include sidebar-icons;
  .nav-item {
    .nav-link {
      color: fade-out($white, 0.5);
      &:active,
      &:focus,
      &:hover {
        color: fade-out($white, 0.25);
      }
    }
  }
  &.toggled {
    width: 0 !important;
    overflow: hidden;
  }

}
.nav-sidebar{
  min-height: 400px !important;
}

.nav-sidebar-container{
  position: relative;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;

  &__admin{
    padding-top: 0px;
  }
  ul{
    flex: 1;
    padding: 0px;
  }
  li{
    padding-left: 28px;
    height: 60px;
  }
  .sidebar_logout{
    margin-left: 28px;
    margin-bottom: 30px;
    .img > svg {
      fill: var(--brand-color-primary);
      height: 26px;
      margin-right: 20px;
    }
  }
  :not(.nav-sidebar__disabled) {
    li{
      &:hover {
        background-color: var(--brand-color-pastel);
        > a > span {
          color: var(--brand-color-primary);
        }
      }
    }
  }
}
.sidebar-network_logout{
  margin-left: 35px;
  margin-bottom: 75px;
  position: absolute;
  bottom: 0;
}

@media (min-width: 768px) {
  .sidebar {
    width: $sidebar-base-width !important;

    .nav-item {
      .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: $sidebar-base-width;
        span {
          font-size: 1rem;
          display: inline;
        }
      }
      .dropdown-menu {
        position: static !important;
        margin: 0 1rem;
        // Position fix for Firefox
        top: 0;
      }
      &.dropdown .dropdown-toggle::after {
        display: block;
      }
    }
    &.toggled {
      overflow: visible;
      width: $sidebar-collapsed-width !important;
      @include sidebar-icons;
    }
  }
}

.nav-sidebar {
  z-index: 49;
  position: sticky;
  top: $navbar-base-height;
  height: calc(100vh - #{$navbar-base-height});
  background-color: #fff !important;
  min-width: $sidebar-base-width;
  box-shadow: $shadow-big;
  &__link {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    text-decoration: none !important;
  }
  .user-sidebar {
    &-img {
      box-shadow: $shadow-elisa;
      width: 150px;
      height: 150px;
      overflow: hidden;
    }
    &-company {
      text-align: center;
      span {
        display: block;
      }
    }
  }


  & > ul {
    & > .is-active {
      box-shadow: 0px 2px 4px #bebebe;
    }
  }
  & .nav-sidebar__disabled{
    li:not(.sidebar_logout){
      cursor: not-allowed;
        .menu-sidebar-color{
          color: #AEAEAE;
        }
        a{
          cursor: not-allowed;
          pointer-events: none;
          span {
            color: #5c5c5c ! important;
          }
        }
      }
  }
}
