$color-default: #000;
$color-identification: #20c6b1;
$color-answers: #6C6C6C;
$color-input: #e9f9f7;
$rounded: 2rem;
$rounded-input: .5rem;

.survey-container{
  max-width: 900px !important;
  margin-left: auto;
  margin-right: auto;
  //navigation
  .survey-nav{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    .survey-nav__element{
      white-space: nowrap;
      cursor: pointer;
      margin-bottom: 3px;
      font-weight: 300;
      padding-top: 0.5rem;
      &:hover{
        span{
          border-bottom: 2px solid $lighter-grey;
        }
        margin-bottom: 1px;
      }
      &.active{
        cursor: initial;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }
}
.survey {
    font-family: lato_medium;
    
    /*** STYLE GENERAL ***/
    &-header {
        background-color: var(--brand-color-primary);
        border-top-left-radius: $rounded;
        border-top-right-radius: $rounded;
        box-shadow: $shadow-elisa;

        h1 {
            @extend .px-3, .px-md-5, .py-3, .m-0;

            color: #FFF;
            text-transform: uppercase;
            font-size: 2rem;

            small {
                @extend .mt-2;

                text-transform: none;
                font-family: 'lato_medium';
                display: block;
                font-size: 65%;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
            }
        }

        &-bottom {
            @extend .px-2, .px-md-4, .py-2;

            background-color: var(--brand-color-pastel);

            >p {
                margin: 0;
                color: var(--brand-color-primary);
                font-size: 1.15rem;
                font-family: 'lato_medium';
            }

            .step {
                @extend .d-flex, .mx-2, .mx-md-4, .my-3;

                span {
                    @extend .me-2;

                    flex: 1 1 auto;
                    height: 8px;
                    background-color: #FFF;
                    transform: skew(45deg);
                    position: relative;

                    &:first-child {
                        &::before {
                            content: " ";
                            position: absolute;
                            display: block;
                            width: 50%;
                            height: 100%;
                            top: 0;
                            left: -5px;
                            z-index: 1;
                            background-color: #FFF;
                            border-radius: $rounded;
                            transform: skew(-45deg);
                        }
                    }

                    &:last-child {
                        &::after {
                            content: " ";
                            position: absolute;
                            display: block;
                            width: 50%;
                            height: 100%;
                            top: 0;
                            right: -8px;
                            z-index: 1;
                            background-color: #FFF;
                            border-radius: $rounded;
                            transform: skew(-45deg);
                        }
                    }

                    &.active {
                        background-color: var(--brand-color-primary);

                        &:first-child, &:last-child {
                            &::before, &::after {
                                background-color: var(--brand-color-primary);
                            }
                        }
                    }
                }
            }
        }
    }


    .custom-control-input{
      z-index: 1;
    }

    &-content {
        margin-top: 2rem;
        border-bottom-left-radius: $rounded;
        border-bottom-right-radius: $rounded;
        position: relative;

        &__helper {
          background: #E8E8E8;
          border-radius: 0 0 0 50%;
          color: #7F7F7F;
          height: 2.6rem;
          padding: 0.5rem 1rem;
          position: absolute;
          right: 0;
          top: 0;
          width: 2.6rem;
          cursor: pointer;
        }

        .form-group {
            box-shadow: $shadow-elisa;
            background-color: #FFF;
            border-radius: 10px;
            margin-bottom: 2rem;
            padding-left: 4rem;
            padding-right: 3rem;
            display: flex;
            flex-direction: column;
            @extend .py-3, .py-md-5;

            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -3rem;
                width: 6px;
                height: 100%;
                background-color: $color-default;
                display: none;
            }

            &.valid {
                &::before {
                    display: block;
                }
            }

            .col-form-label {
                @extend .d-flex, .align-items-center;
            }

            .form-control {
                @extend .form-control-lg;

                border: 0;
                border-radius: $rounded-input;
                background-color: lighten($color-input, 3);
                height: calc(1.5em + 2rem + 2px);

                &:focus, &:active, &:hover {
                    background-color: $color-input;
                    box-shadow: none;
                }
            }

            .form-identification {
                font-size: 1.10rem;
            }

            .number-question {
                @extend .mb-3;

                font-size: 1.25rem;

                span {
                  display: inline-block;
                  font-size: 1rem;
                  color: #FFF;
                  position: absolute;
                  left: -5px;
                  border-bottom-right-radius: $rounded-input;
                  border-top-right-radius: $rounded-input;
                  background-color: black;
                  margin-top: 3px;
                  padding-left: 1.6rem !important;
                  padding-right: 0.5rem !important;
                  label{
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-bottom: 8px solid $color-default;
                    border-left: 5px solid transparent;
                    margin-left: -1.6rem;
                    margin-top: -0.5rem;
                  }

                }
            }
        }

        &-submit {
            @extend .my-4;

            .btn {
                @extend .btn-lg;

                background-color: var(--brand-color-primary);
                border: 0 solid var(--brand-color-primary);
                color: #FFF;
                border-radius: $rounded-input;
            }
        }
    }

    &.identification {
      max-width: 750px !important;
      .form-group{
        flex-direction: row;
      }
      font-size: 1.1rem;
      .survey-header {

        h1 {
          @extend .my-4, .pt-4, .d-flex, .align-items-center;

          position: relative;
          text-transform: unset;

          &::after {
            content: '';
            display: block;
            position: absolute;
            background: #FFF;
            width: 10%;
            height: 2px;
            bottom: 0;
            left: 0;
            margin-left: 2.5rem;

            @include media-breakpoint-down(sm) {
              margin-left: 1rem;
            }
          }

          i {
            @extend .me-5;
          }
        }
      }

      .survey-content {
        .form-group {
          &::before {
            background-color: var(--brand-color-primary);
          }
        }

        &-submit .btn {
          background-color: var(--brand-color-primary);
        }
      }
    }


  .btn-cta-offers {
        @extend .btn, .btn-lg, .text-uppercase, .my-4, .px-5;

        background-color: $color-identification;
        color: #FFF;
        font-size: 1.25rem;

        &:hover {
            opacity: .6;
            color: #FFF;
        }
    }

    .explanation-offers {
        font-size: 1.15rem;
        width: 80%;
        margin: 0 auto;
    }
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  margin-left: -0.8rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
  margin-left: -0.8rem;
}

.custom-free-input{
  color: $color-answers;
  margin-top: 20px;
  width: 100%;
  outline: none;
  &:focus{
    padding-bottom: 3px;
  }
}

@each $family-name in $domain-families {
  .survey-container .survey-nav .survey-nav__element.active.domain-family-#{$family-name} {
    color: var(unquote('--brand-domain-family-' + $family-name));
  }

  .survey.domain-family-#{$family-name} {
    .survey-header {
      background-color: var(unquote('--brand-domain-family-' + $family-name));
    }

    .survey-header-bottom {
      background-color: var(unquote('--brand-pastel-domain-family-' + $family-name));

      .step span.active {
        background-color: var(unquote('--brand-domain-family-' + $family-name));

        &:first-child, &:last-child {
          &::before, &::after {
            background-color: var(unquote('--brand-domain-family-' + $family-name));
          }
        }
      }
    }

    .survey-content .form-group {
      .number-question span {
        background-color: var(unquote('--brand-domain-family-' + $family-name));

        label {
          border-bottom-color: var(unquote('--brand-domain-family-' + $family-name));
        }
      }

      .custom-checkbox .custom-control-input:checked~.custom-control-label {
        &::after {
          background-color: var(unquote('--brand-domain-family-' + $family-name));
        }
      }

      .custom-radio .custom-control-input:checked~.custom-control-label {
        &::before {
          background-color: var(unquote('--brand-domain-family-' + $family-name));
          border-color: var(unquote('--brand-domain-family-' + $family-name));
        }
      }


      .custom-free-input:focus{
        border: var(unquote('--brand-domain-family-' + $family-name)) solid 1px;
      }
    }

    .survey-content-submit .btn {
      background-color: var(unquote('--brand-domain-family-' + $family-name));
      border: 0 solid var(unquote('--brand-domain-family-' + $family-name));
    }
  }
}

.survey-content-identification {
  box-shadow: $shadow-elisa;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  @extend .py-3, .py-md-5;

  position: relative;
}

.identification-section-title {
  margin-top: 50px;
  margin-bottom: 60px;
}

.identification-rgpd-info {
  font-size: 9px;
  margin-top: 40px;
  text-align: justify;
}
