.assistance-systems {
  h1 {
    font-size: 2rem;
    margin-bottom: 0.25rem;
    font-weight: 300;
  }

  .subtitle {
    font-size: 0.9rem;
    margin-bottom: 2rem;
    font-weight: 300;
  }
}

.assistance-systems-bloc {
  background-color: #ffffff;
  padding: 2rem;
  margin: 1rem 0 0;
  border-radius: 0.85rem;
  box-shadow: $shadow-elisa;
  position: relative;

  h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    color: var(--brand-color-primary);
    font-weight: 400;
  }

  p {
    margin: 0;
    margin-right: 60px;
    font-weight: 300;
  }

  a {
    display: flex;
    position: absolute;
    right: 2rem;
    bottom: 1.5rem;
    font-size: 1.5rem;
    border-radius: 100%;
    box-shadow: $shadow-elisa;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    color: #000000;
    &:hover {
      color: var(--brand-color-primary);
      text-decoration: none;
    }
  }
}

.upgrades-download-container {
  text-align: end;
  font-size: 0.9rem;
  padding-top: 6px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: var(--brand-color-primary) !important;
  }
}
