@mixin marque($primary-color, $secondary-color, $pastel-color) {
  &-inactive {
    color: black;
  }

  &.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $primary-color;
  }

  &.form-control:focus {
    border-color: $primary-color;;
  }

  &-active {
    color: $primary-color;
  }

  &.btn-primary, &.btn, &.btn-reseau {
    background-color: $secondary-color;
    border: 0 solid $secondary-color;

    &:hover, &:focus {
      background-color: darken($secondary-color, 8%);
      border-color: darken($secondary-color, 8%);
    }
  }

  .custom-control .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  &.welcome-dashboard h3 {
    color: $primary-color;
  }

  &.navbar-actions .display-inline {
    background: $primary-color;
  }

  &.navbar-actions .content-menu-lg-menu > .chevron_menu {
    color: $primary-color;
  }

  &.text-colored {
    color: $primary-color;
  }

  &.page-documents .documents-item-file .file-uploaded .custom-file-label {
    color: $primary-color;
    border-color: $secondary-color;
  }

  &.page-documents .documents-item-file .file-uploaded:hover .custom-file-label {
    background-color: $secondary-color;
    color: white;
  }

  &.bp-card-head {
    color: $primary-color;
  }

  &.upgrades__upgrade__progress_bar__point__filled, &.upgrades__upgrade__progress_bar__point__filled-suivi {
    background-color: $primary-color;
  }

  &.btn-reseau-spec-span {
    border-color: $primary-color;
    color: $primary-color;
  }

  &.btn-reseau-2 {
    color: white;
  }

  &.btn-inverted {
    background-color: transparent;
    color: $secondary-color;
    border: 1px solid $secondary-color;

    &:hover, &:focus {
      color: white;
    }

    &.btn-focus-disabled {
      &:focus {
        background-color: transparent;
        color: $secondary-color;
        border: 1px solid $secondary-color;
      }
    }
  }

  &.btn-inverted-white {
    background-color: white;
    color: $secondary-color;
    border: 1px solid $secondary-color;

    &:hover, &:focus {
      color: white;
    }

    &.btn-focus-disabled {
      &:focus {
        background-color: transparent;
        color: $secondary-color;
        border: 1px solid $secondary-color;
      }
    }
  }

  &.dropdown-date {
    color: $primary-color;
  }

  &.menu-sidebar-color:hover {
    color: $primary-color;
  }

  &.diagnostic-info {
    .text-primary {
      color: $primary-color !important;
    }
  }

  &.diagnostic-global {
    .diagnostic-global-details p span:nth-child(2) {
      color: $primary-color !important;
    }

    .diagnostic-global-details:after {
      background-color: $primary-color;
    }
  }

  &.progressbar-text {
    color: $secondary-color;
  }

  #upgrade .upgrades__upgrade__progress-bar .progress .progress-bar {
    background-color: $pastel-color !important;;
  }

  .spinner {
    .double-bounce1, .double-bounce2 {
      background-color: $primary-color !important;;
    }
  }

  .login-page {
    .input-group-text {
      color: $primary-color !important;
      background-color: #fff !important;
      padding: 0.64rem 0.75rem !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.aderly {
  $primary-color: #f66767;
  $secondary-color: #EE2839;
  @include marque($primary-color, $secondary-color, #f3baba);
}

body.aderly, .modal-body.aderly {
  --brand-color-primary: #f66767;
  --brand-color-secondary: #EE2839;
  --brand-color-pastel: #f3baba;

  --brand-gradients-family-default: linear-gradient(269.95deg, #F25E5E 13.13%, #F25E5E 71.54%);
  --brand-domain-family-default: #F25E5E;
  --brand-pastel-domain-family-default: #f3baba;
}

.grandlyon {
  $primary-color: #e4200e;
  $secondary-color: #e4200e;
  $pastel-color: #f3baba;
  @include marque($primary-color, $secondary-color, $pastel-color);
}

body.grandlyon, .modal-body.grandlyon {
  --brand-color-primary: #e4200e;
  --brand-color-secondary: #e4200e;
  --brand-color-pastel: #f3baba;

  --brand-gradients-family-default: linear-gradient(269.95deg, #e4200e 13.13%, #e4200e 71.54%);
  --brand-domain-family-default: #e4200e;
  --brand-pastel-domain-family-default: #f3baba;
}


.rennes {
  $primary-color: #00a897;
  $secondary-color: #00a897;
  $pastel-color: #BFE3DF;
  @include marque($primary-color, $secondary-color, $pastel-color);
}

body.rennes, .modal-body.rennes {
  --brand-color-primary: #00a897;
  --brand-color-secondary: #00a897;
  --brand-color-pastel: #BFE3DF;

  --brand-gradients-family-default: linear-gradient(269.95deg, #00a897 13.13%, #00a897 71.54%);
  --brand-domain-family-default: #00a897;
  --brand-pastel-domain-family-default: #BFE3DF;
}


.lille {
  $primary-color: #7c2665;
  $secondary-color: #7c2665;
  $pastel-color: #FACED2;
  @include marque($primary-color, $secondary-color, $pastel-color);
}

body.lille, .modal-body.lille {
  --brand-color-primary: #7c2665;
  --brand-color-secondary: #7c2665;
  --brand-color-pastel: #FACED2;

  --brand-gradients-family-default: linear-gradient(269.95deg, #7c2665 13.13%, #7c2665 71.54%);
  --brand-domain-family-default: #7c2665;
  --brand-pastel-domain-family-default: #FACED2;
}


.genethic {
  @include marque(#00c7b1, #1FC5AF, #e9f9f7);
}

body.genethic, .modal-body.genethic {
  --brand-color-primary: #00c7b1;
  --brand-color-secondary: #1FC5AF;
  --brand-color-pastel: #e9f9f7;

  --brand-gradients-family-activity: linear-gradient(269.95deg, #9A9A9A 13.13%, #656565 71.54%);
  --brand-gradients-family-default: linear-gradient(269.95deg, #e9f9f7 13.13%, #00c7b1 71.54%);
  --brand-gradients-family-diagnostic: linear-gradient(269.95deg, #EE2839 13.13%, #f66767 71.54%);
  --brand-gradients-family-ecology: linear-gradient(269.95deg, #DADC55 13.13%, #9EC827 71.54%);
  --brand-gradients-family-economy: linear-gradient(270deg, #8CB1FA 20.56%, #5481DB 128.5%);
  --brand-gradients-family-governance: linear-gradient(270deg, #FF5A97 20.56%, #C73A6E 128.5%);
  --brand-gradients-family-social: linear-gradient(270deg, #FFD600 20.56%, #FC991C 128.49%, #FFB422 128.5%);

  --brand-domain-family-activity: #656565;
  --brand-domain-family-default: #656565;
  --brand-domain-family-diagnostic: #f66767;
  --brand-domain-family-ecology: #9EC827;
  --brand-domain-family-economy: #5481DB;
  --brand-domain-family-governance: #C73A6E;
  --brand-domain-family-social: #FFB421;

  --brand-pastel-domain-family-activity: #b2b2b2;
  --brand-pastel-domain-family-default: #b2b2b2;
  --brand-pastel-domain-family-diagnostic: #fbb4b4;
  --brand-pastel-domain-family-ecology: #cee887;
  --brand-pastel-domain-family-economy: #acc2ee;
  --brand-pastel-domain-family-governance: #e39db7;
  --brand-pastel-domain-family-social: #ffda91;
}

.mcapital {
  $primary-color: #FF6720;
  $secondary-color: #FF6720;
  $pastel-color: #ffffff;

  @include marque($primary-color, $secondary-color, $pastel-color);
}
body.mcapital, .modal-body.mcapital {
  --brand-color-primary: #FF6720;
  --brand-color-secondary: #FF6720;
  --brand-color-pastel: #f9d0a0;

  --brand-gradients-family-default: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-activity: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-diagnostic: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-ecology: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-economy: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-governance: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);
  --brand-gradients-family-social: linear-gradient(269.95deg, #FF6720 13.13%, #FF6720 71.54%);

  --brand-domain-family-activity: #FF6720;
  --brand-domain-family-default: #FF6720;
  --brand-domain-family-diagnostic: #FF6720;
  --brand-domain-family-ecology: #FF6720;
  --brand-domain-family-economy: #FF6720;
  --brand-domain-family-governance: #FF6720;
  --brand-domain-family-social: #FF6720;
  --brand-pastel-domain-family-activity: #ffffff;
  --brand-pastel-domain-family-default: #ffffff;
  --brand-pastel-domain-family-diagnostic: #ffffff;
  --brand-pastel-domain-family-ecology: #ffffff;
  --brand-pastel-domain-family-economy: #ffffff;
  --brand-pastel-domain-family-governance: #ffffff;
  --brand-pastel-domain-family-social: #ffffff;

}

.bordeaux, .village {
  $primary-color: #F3A140;
  $secondary-color: #F3A140;
  $pastel-color: #f9d0a0;

  @include marque($primary-color, $secondary-color, $pastel-color);
}
body.bordeaux, .modal-body.bordeaux, body.village, .modal-body.village {
  --brand-color-primary: #F3A140;
  --brand-color-secondary: #F3A140;
  --brand-color-pastel: #f9d0a0;

  --brand-gradients-family-default: linear-gradient(269.95deg, #F3A140 13.13%, #F3A140 71.54%);
  --brand-domain-family-default: #F3A140;
  --brand-pastel-domain-family-default: #f9d0a0;
}


.ninkasi {
  $primary-color: #FF3232;
  $secondary-color: #FF3232;
  $pastel-color: #FFE6E6;

  @include marque($primary-color, $secondary-color, $pastel-color);
}
body.ninkasi, .modal-body.ninkasi {
  --brand-color-primary: #FF3232;
  --brand-color-secondary: #FF3232;
  --brand-color-pastel: #FFE6E6;

  --brand-gradients-family-default: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-activity: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-diagnostic: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-ecology: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-economy: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-governance: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);
  --brand-gradients-family-social: linear-gradient(269.95deg, #FF3232 13.13%, #FF3232 71.54%);

  --brand-domain-family-activity: #FF3232;
  --brand-domain-family-default: #FF3232;
  --brand-domain-family-diagnostic: #FF3232;
  --brand-domain-family-ecology: #FF3232;
  --brand-domain-family-economy: #FF3232;
  --brand-domain-family-governance: #FF3232;
  --brand-domain-family-social: #FF3232;
  --brand-pastel-domain-family-activity: #ffffff;
  --brand-pastel-domain-family-default: #ffffff;
  --brand-pastel-domain-family-diagnostic: #ffffff;
  --brand-pastel-domain-family-ecology: #ffffff;
  --brand-pastel-domain-family-economy: #ffffff;
  --brand-pastel-domain-family-governance: #ffffff;
  --brand-pastel-domain-family-social: #ffffff;
}


.paca {
  $primary-color: #0075BF;
  $secondary-color: #0075BF;
  $pastel-color: #83D0F5;
  @include marque($primary-color, $secondary-color, $pastel-color);
}

body.paca, .modal-body.paca {
  --brand-color-primary: #0075BF;
  --brand-color-secondary: #0075BF;
  --brand-color-pastel: #83D0F5;

  --brand-gradients-family-default: linear-gradient(269.95deg, #0075BF 13.13%, #0075BF 71.54%);
  --brand-domain-family-default: #0075BF;
  --brand-pastel-domain-family-default: #83D0F5;
}
