.page-item.active .page-link {
  background-color: var(--brand-color-primary);
  border-color: var(--brand-color-primary);
}

.page-link {
  color: var(--brand-color-primary);
}

.page-link:hover {
  color: var(--brand-color-primary);
}
