.good-practices-container{
  width: 70% !important;
  .practices{
    display: flex;
    flex-wrap: wrap;
    .practices__element{
      width: 33.333%;
      .container-clickable{
        margin: 10px;
        border-radius: 15px;
        cursor: pointer !important;
        box-shadow: $shadow-elisa;
        background-color: white;
        .container-clickable__arrow{
          background-color: white;
          // width: 4em;
          width: 15%;
          position: relative;
          // padding: 12px;
          padding: 1.9%;
          border-radius: 60px;
          margin-left: 72%;
          // margin-top: -33px;
          margin-top: -7.5%;
          box-shadow: $shadow-elisa;
        }
        .container-clickable__sous-section{
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          padding-left: 1.1em;
          padding-bottom: 1.1em;
          height: 120px;
          label:nth-child(1){
            color: $lighter-grey;
            font-weight: 600;
            font-size: 1.2em;
            max-width: 75%;
            cursor: pointer;
          }
          label:nth-child(2){
            display: block;
            font-weight: 300;
            font-size: 0.9em;
            cursor: pointer;
          }
        }
      }
      img{
        // max-width:100%;
        // max-height:100%;
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }

    @media (max-width: 800px) {
      .practices__element{
        width: 50%;
      }
    }


    @media (max-width: 600px) {
      .practices__element{
        width: 100%;
      }
    }

  }

}

