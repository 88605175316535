// Global styling for this template

html {
  position: relative;
  height: 100vh;
}

body {
  height: inherit;
  overflow-y: auto;
}

#wrapper {
  display: flex;
}
#content-wrapper {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, 0.5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}

.scroll-window{
  width: 100%;
  overflow-y: auto;
}
