// Radius
$border-radius: .85rem;

#adn-diag, #upgrade {

  .progress-bar {
    background-color: black;
  }

    .diagnostic {
        @extend .px-md-5;

        position: relative;
        border-radius: $border-radius;

        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 205px;
                height: 100%;
                z-index: -1;
                //background: url('#{$assetPath}adn-nb.png') no-repeat top center;
                background-size: auto auto;
                opacity: .6;
            }
        }

        &-domain {
            @extend .shadow-sm,
            .bg-white;

            border-radius: $border-radius;

            &:not(:first-child) {
                @extend .mt-5;
            }

            &-title {
                @extend .d-flex,
                .justify-content-between,
                .align-items-center,
                .pt-4,
                .pt-md-5,
                .px-4,
                .px-md-5;

                >h2 {
                    @extend .mb-0;

                    font-weight: 400;
                }

                >span {
                    font-size: 1.6rem;
                }
            }

            &-sub {
                @extend .mt-5,
                .pb-5;

                .subdomain {
                    @extend .py-2;

                    &-header {
                        @extend .d-flex,
                        .align-items-center,
                        .px-4,
                        .px-md-5;

                        flex-basis: 100%;
                        cursor: pointer;

                        @include media-breakpoint-down(md) {
                            flex-wrap: wrap;
                        }

                        .subdomain-header-link {
                          font-size: 0.9em;
                          &:hover {
                            text-decoration: none;
                          }
                        }

                        &-title {
                            flex: 1 1 35%;

                            @include media-breakpoint-down(sm) {
                                flex: 0 0 100%;
                                display: flex;
                                justify-content: space-between;

                                >span:nth-child(2) {
                                    color: $green-hover;
                                    text-decoration: underline;
                                }
                            }
                        }

                        &-progress {
                            @extend .shadow-sm;

                            flex: 1 1 40%;
                            height: .85rem;
                            border-radius: $border-radius;

                            @include media-breakpoint-down(md) {
                                flex: 1 1 35%;
                            }

                            .progress-bar {
                                border-radius: $border-radius;
                                transition: all 0.9s ease;
                                width: 0px;
                            }
                        }

                        &-link {
                            flex: 1 1 15%;
                            text-align: center;
                            text-decoration: underline;
                        }

                        &-note {
                            flex: 1 1 10%;
                            text-align: right;
                        }
                    }

                    &-body {
                        @extend .shadow-sm,
                        .p-3,
                        .p-md-5,
                        .mt-4,
                        .mb-5,
                        .mx-1,
                        .mx-md-5,
                        .bg-white;

                        border-radius: $border-radius;

                        &-row {
                            @extend .d-flex,
                            .justify-content-between,
                            .py-2,
                            .flex-wrap,
                            .flex-md-nowrap;

                            font-size: .85rem;

                            >span {
                                flex: 1 1 50%;

                                @include media-breakpoint-down(sm) {
                                    flex: 0 0 100%;
                                }

                                &.awnser {
                                    @extend .text-right !optional;
                                    @extend .text-colored;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

  @each $family-name in $domain-families {
    .domain-family-#{$family-name} {
      .diagnostic-domain-title {
        >h2, >span {
          color: var(unquote('--brand-domain-family-' + $family-name));
        }
      }

      .subdomain-header-progress {
        .progress-bar {
          background: var(unquote('--brand-domain-family-' + $family-name));
          background: var(unquote('--brand-gradients-family-' + $family-name));
        }
        .progress-bar.plain-progress-bar {
          background: var(unquote('--brand-domain-family-' + $family-name));
        }
      }
    }
  }

    .upgrades__upgrade__progress-bar {
        .progress {
            flex: 1 1 40%;
            height: .5rem;
            border-radius: $border-radius;

            @include media-breakpoint-down(md) {
                flex: 1 1 35%;
            }

            .progress-bar {
                border-radius: $border-radius;
                transition: all 0.9s ease;
                width: 0px;
            }
        }
    }
}

#content-wrapper.page-adn {
    position: relative;
    overflow: visible;

    .adn-nb {
        display: block;
        position: absolute;
        top: -125px;
        right: 0 !important;
        height: calc(100% + 125px);
        width: 205px;
        background-repeat: repeat-y;
        background-image: url('../../images/medias/adn-big-nb.png');
        background-position: left 10px;
        opacity: .5;

        @media (min-width: 1600px) {
            right: 22%;
        }
    }
}
.diagnostic-header{
  margin-left:0px;
  margin-right: 0px;
}

.diagnostic-domain-shadow-elisa{
  margin-top: 3rem ;
  border-radius: 0.85rem;
  background-color: #fff;
  box-shadow: $shadow-elisa;
}

.diagnostic-global{
  width:55%;
  display: flex;
  padding: 3rem ;
  padding-left: 2rem;
  padding-right:1rem;
  box-shadow: $shadow-elisa;
  position: relative ;
  background-color: #fff;
  border-radius: .85rem;
  .diagnostic-global-details{
    -ms-flex-preferred-size: 50%;
    flex-basis: 80%;
    position: relative;
    padding-right: 2rem;
    &:after{
      content: '';
      display: inline-block;
      width: 1px;
      height: 80%;
      background-color: $green-hover;
      position: absolute;
      right: 0;
      top: 10%;
    }
    p{
      font-size: 0.85rem;
      justify-content: space-between;
      display: flex;
      margin-bottom: 1rem;
      span:nth-child(2){
        color: $green-hover;
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }


  }

  .diagnostic-global-note {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;

    .progress-bar-circle {
      svg {
        path.progress-bar-circle-indicator {
          stroke: var(--brand-color-primary);
        }
      }
    }
  }
}

.diagnostic-info{
  width: 45%;
  .text-primary{
    color: $green-hover !important;
  }
  .diagnostic-info-head{
    display: flex;
    margin-bottom: 1.5rem;
    .diagnostic-info-head-img{
      overflow: hidden;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      width: 145px;
      height: 145px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 50%;
      margin-right: 1.5rem;
      background-color: white;
      box-shadow: $shadow-elisa;
      img{
        height: 65px;
        width: 65px;
        margin-left: 40px;
        margin-top: 40px;
      }
    }

    .diagnostic-info-head-title{
      margin-top: 2.6rem;
      flex: 1;
      text-align: center;
    }

    .h3{
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  .diagnostic-info-desc {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;

    button {
      color: var(--brand-color-primary);
      font-size: 0.9rem;
      font-weight: 300;

      span {
        margin-left: 20px;
        text-decoration: underline;
      }
    }
  }
}


@media (max-width:1180px) {
  .diagnostic-info, .diagnostic-global{
    width: 100%;
  }


}

.progress-bar-circle{
  width: 8rem;
  height: 8rem;
  svg{
    transform: scaleX(-1);
  }
  path:nth-child(2){
    transition: stroke-dashoffset 0.9s linear;
    stroke-dasharray: 289.067px, 289.067px;
    stroke-dashoffset: 289.067px;
  }
}
.progressbar-text{
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  color: $green-hover;
  font-size: 2.5rem;
}

.reseau-card{
  font-family: 'Montserrat', sans-serif;
  border-radius: 21px !important;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  &__company{
    width: 200px;
    font-size: 0.9rem;
  }
  &__fond{
    width: 160px;
    font-size: 0.9rem;
  }
  &__project-name{
    width: 160px;
    font-size: 0.9rem;
  }
  &__body{
    padding-left: 4rem;
    flex: 1;
    font-size: 0.85rem;
    span{
      font-size: 1rem;
    }
  }
  &__buttons{
    display: flex;
    justify-content: flex-end;
    width: 200px;
  }
  @media (max-width:780px) {
    &__fond{
      display: none;
    }
    &__project-name{
      display: none;
    }
  }
}


.custom-collapse {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}
.custom-collapse.custom-collapse-opened {
  max-height: 5000px; // must be above the max we will ever get
  transition: max-height 0.25s ease-in;
}
