@font-face {
  font-family: LatoSemiBold;
  src: url('../../fonts/lato-semibold.ttf');
}

@font-face {
  font-family: 'din_alternatebold';
  src: url('../../fonts/din_alternate_bold-webfont.woff2') format('woff2'),
    url('../../fonts/din_alternate_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'din_condensedbold';
  src: url('../../fonts/din_condensed_bold-webfont.woff2') format('woff2'),
    url('../../fonts/din_condensed_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lato_medium';
  src: url('../../fonts/lato-medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: local('Montserrat Thin'), local('Montserrat-Thin'), url('../../fonts/Montserrat-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url('../../fonts/Montserrat-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: local('Montserrat Light'), local('Montserrat-Light'), url('../../fonts/Montserrat-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('../../fonts/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('../../fonts/Montserrat-SemiBold.woff2') format('woff2');
}

