// Import Custom Variables
@import 'abstracts/variables.scss';

// Import FontAwesome
@import '@fortawesome/fontawesome-free/scss/fontawesome';
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';

// Import Bootstrap
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr-bs5-alert';

@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

// Import Custom SCSS
@import 'abstracts/mixins.scss';
@import 'base/global.scss';
@import 'abstracts/utilities.scss';
@import 'abstracts/fonts.scss';
@import 'layout/navbar.scss';
@import 'layout/container.scss';
@import 'components/select2.scss';
@import 'components/cards.scss';
@import 'components/spinner.scss';
@import 'components/paginator.scss';
@import 'layout/footer.scss';
@import 'pages/survey';
@import 'pages/adn';
@import 'pages/assistance_systems';
@import 'pages/documents';
@import 'pages/good_practices.scss';
@import 'pages/suivi.scss';
@import 'pages/parameters.scss';
@import 'marque.scss';

body {
  font-family: LatoSemiBold;
}

h1, h2, h3 {
  font-weight: 400;
  > small {
    font-weight: 400;
  }
}
.fw-400 {
  font-weight: 400;
}

a {
  text-decoration: none;
}

label {
  display: initial;
}

.container-padding {
  padding-left: 48px !important;
}

.legend{
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 22px;
}

.h2-bold{
  font-size:1.1rem;
  font-weight: 500;
  line-height: 20px;
}

.shadow-elisa{
  box-shadow: $shadow-elisa;
}

.login-page{
  background-color: $background-color;
  min-height: 100%;
  .container-login{
    max-width: 570px;
    margin: 0 auto;
    padding: 8em 0 1em;

    .card-login{
      background-color: white;
      border-radius: 2rem;
      margin: 1em;
      padding: 4rem;
      padding-top: 3rem;
      box-shadow: $shadow-elisa;

      h3 {
        img {
          max-width: 230px;
          max-height: 70px;
        }
      }

      img{
        margin-bottom: 1rem;
      }
      .form-group__register{
        text-align: center;
        margin-top: 1rem;
      }

      .password-div {
        position: relative;
        .password-eye-icon {
          position: absolute;
          top: 9px;
          right: 10px;
          cursor: pointer;
          color: #ccc;
          z-index:99;
        }
      }

    }

  }
}

.register-password-indication {
  font-size: 0.7rem;
  font-weight: 300;
}
.register-password-indication.valid {
  color: green;
}
.register-password-indication.invalid {
  color: red;
}

.img-shortened {
  max-height: 80%;
}

//TODO a changer avec variables de la marque blanche

.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn:focus, .form-control:focus{
  box-shadow: $shadow-elisa;
}


/* Styles par défaut (écran de bureau) */
.menu-sidebar-color {
  color:#000000;
  font-size:15px;
}

.menu-sidebar li img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}
.menu-sidebar .sidebar_home img {
  width: 30px;
  height: 30px;
  margin-right: 18px;
  margin-left: -4px;
}


.menu-sidebar li.sidebar_assistance_systems img {
  width: 33px;
  margin-left: -4px;
  height: 33px;
  margin-right: 17px
}

.menu-sidebar li.sidebar_audience img {
  width: 25px;
  margin-left: 2px;
  height: 25px;
  margin-right: 19px;
}

.menu-sidebar-color {
  &:hover {
    color: $green-hover;
  }

  span {
    width: 40px;
    height: 20px;
    text-align: right;
  }
}

.span-badge {
  width: 60px;
  height: 25px;

  position: absolute;

  padding: 0 !important;

  right: 20px;
  top: 50%;

  transform: translateY(-50%);

  color: #fff;
  text-align: center;
  line-height: 25px;
}

.suivi-container {
  margin-top: 20px;
}


.card {
  border-radius:5px;
  margin-bottom:30px;
  border:none;
  box-shadow: $shadow-elisa;

}
.card-style {
  position: relative;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  margin: 0 auto !important;
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
  max-width: 350px;
  min-width: 300px;
  display: block;
}
.card-style .card-body a {
  position: absolute;

  padding: 5px 25px;

  background-color: #00C7B1;
  color: white;
  bottom: 0;
  right: 0;
}

.container-rouned-class span {
  width: 100px;
  display: inline-block;
  padding: 20px 4px;
  margin-right: 20px;
}

.dropdown-item{
  cursor: pointer;
}

/*
  UPGRADE PRO
  */
.navbar-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .navbar-button {
    margin-right: 50px;
    padding: 10px 30px;

    font-weight: 600;

    border: 2px solid #ffc107;

    &:hover {
      color: #fff;
    }
  }
  .display-inline {
    width: 38px;
    height: 38px;

    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #00C7B1;
    border-radius: 50%;
    color: white;
    position: relative;
  }


  .content-menu-lg-menu {
    display: inline-block;
    position: relative;

    margin: 7px 3px 0;

    & > .chevron_menu {
      color: #00C7B1;
      font-size: 1.2em;
    }
  }
  .menu-lg-menu {
    position: absolute;
    left: -250px;
    top: 40px;
    border-radius: 15px;
    box-shadow: 0 2px 5px #b5b5b5;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: inherit;
  color: black;
}


/* Smartphone en mode paysage et résolutions inférieures */
@media (max-width: 900px) {
  /**
        sidebar
     */
  .container-sidebar {
    width: 200px;
    background-color: red;
  }

  .nav-sidebar {
    vertical-align: top;
    min-height: 400px;
    position: fixed;
    left: 0;

    z-index: 49;

    & > ul {
      padding-top: 75px;

      & > li {
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        height: fit-content;
        min-height: 100px;

        padding: 5px 10px;

        & > * {
          margin: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #aaa;
        }

        & > .badge {
          position: static;
          transform: translateY(0);
        }
      }
    }
  }
  .menu-sidebar {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
    color: #4e555b;
  }
  #content-wrapper {
    min-height: 600px;
    margin-top: 50px;
  }

  .upgrade_pro {
    display: none;
  }


  /*
        end sidebar
     */

  .container-second {
    flex: available;
  }

  .menu-hamburger {
    display: inline-block;
    margin-left: 15px;
  }
  .container-second h4 {
    display: inline-block;
  }

  .form-register {
    border-radius: 10px;
  }
}

.login-form {
  padding-top: 1rem;
}

.pro {
  margin: 0 auto;

  height: 100%;

  font-family: 'lato_medium';

  & > * {
    height: fit-content;

    margin: auto 0;

    & > * {
      max-width: 400px;
      margin: 15px auto;
    }
  }

  & form,
  &__list {
    padding: 0 50px;
  }

  & form {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-right: 1px solid #aaa;

    & > * {
      box-sizing: border-box;
    }
  }

  &__title {
    & > h2 {
      font-size: 1.7rem;
      font-family: Arial;
      text-transform: uppercase;
    }
  }

  &__label {
    width: 100%;

    font-weight: bold;

    &__input {
      outline: none;

      width: 100%;
      height: 45px;

      padding: 0 20px;

      border-radius: 8px;
      border: 2px solid #aaa;
    }
  }

  &__side-by-side {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > * {
      width: 45% !important;
    }
  }

  &__checkbox {
    width: 100%;

    margin: 10px 0;

    display: inline-flex;

    cursor: pointer;

    & > a {
      display: contents;
    }
  }

  &__submit {
    border: none;
    outline: none;

    margin-top: 25px;

    height: 50px;
    width: 100%;

    background-color: #00C7B1;
    color: #fff;

    text-transform: uppercase;
    font-weight: bold;

    border-radius: 10px;

    &:disabled {
      background-color: grey;
    }
  }



  &__list {
    display: flex;
    flex-direction: column;

    &__elem {
      display: flex;
      margin-left: 0;

      &__icon {
        width: 25px;
        height: 25px;

        margin-right: 15px;
      }

      &__title {
        font-size: 1.2em;
        font-weight: bold;
      }

      &__description {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 1250px) {
  .pro {
    display: block;
    & > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }

    & > form {
      border-right: none;
      border-bottom: 1px solid #aaa;
    }

    &__list {
      display: block;
    }
  }
}

.offers {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  &__header {
    text-align: center;

    &__title {
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    &__subtitle {
      font-size: 1.2em;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .card-deck {
    //margin: 25px;
    @extend .px-4;

    & > * {
      width: 100%;
    }
  }

  .card-header {
    text-align: center;
    color: #000;
    border-top: 1.75rem solid;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #FFF;
    border-bottom: 0;

    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 1px;
      margin: auto;
      background-color: #b8b8b8;
      margin-top: 2rem;
    }

    h2 {
      text-transform: uppercase;
      color: #404040;
      font-size: 1.85rem;
      margin: 1rem 0;
      margin-bottom: 0.6rem;
    }

    >p {
      line-height: 2rem;

      .price {
        font-size: 2.5rem;
        font-weight: bold;
        font-family: 'lato_medium';
        position: relative;

        &::after {
          content: '€';
          position: absolute;
          right: -12px;
          top: 0;
          font-size: 1.3rem;
          font-weight: normal;
        }
      }
    }

    .btn {
      font-size: 1.5rem;
      font-weight: 100;
      font-family: 'lato_medium';
      border: 1px solid;
      padding: .3rem 2rem;
      border-radius: 1rem;
    }
  }

  .card-body {
    padding: 10px 15px;

    .disabled {
      opacity: 0.2;
    }

    &__list {
      padding: 0;

      list-style-type: none;

      &__elem {
        margin: 15px 0;
        display: flex;

        &__icon {
          margin-right: 10px;
          display: inline-block;
        }

        &__text {
          font-size: 1.10rem;
        }
      }
    }
  }

  & .cardOffer-diag {
    $color: #f2accd;

    .card-header {
      border-color: $color;

      .btn {
        border-color: $color;
        color: $color;

        &:hover {
          background: $color;
          color: #FFF;
        }
      }
    }
  }

  & .cardOffer-com {
    $color: #20c6b1;

    .card-header {
      border-color: $color;

      .btn {
        border-color: $color;
        color: $color;

        &:hover {
          background: $color;
          color: #FFF;
        }
      }
    }
  }

  & .cardOffer-pro {
    $color: #edc056;

    .card-header {
      border-color: $color;

      .btn {
        border-color: $color;
        color: $color;

        &:hover {
          background: $color;
          color: #FFF;
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .offers {
    margin-top: 50px;
  }
}

.upgrades-page {
  &__container {
    display: flex;
    flex-direction: row;
  }

  &__content {
    flex: 10;
  }

  &__spacer-before {
    flex: 1;
  }

  &__spacer {
    flex: 2;
  }
}

.upgrades {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  padding-left: 0;

  &-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: fit-content;

    margin: auto;

    & > * {
      margin: 20px;
    }
  }

  & > .selected {
    padding-right: 0;
    & > button {
      display: none;
    }
  }

  &__upgrade {
    $button-with: 130px;

    margin: 17px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: $shadow-elisa;

    border-left: 6px solid var(--brand-color-primary);

    position: relative;

    &__progress-bar,
    &__name {
      margin: 17px auto;
      font-size: 1rem;
    }

    &__progress-bar {
      display: flex;
      align-items: center;

      &__bar {
        width: 200px;
        height: 15px;

        background-color: #ccc;

        &__filled {
          height: 100%;
          width: 0;

          background-color: red;
        }
      }

      &__percent {
        margin: 0 10px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &__button {
      border: none;
      outline: none;

      width: $button-with;
      height: 36px;

      right: 0;
      top: 0;
      padding: 0 20px;

      background-color: #00C7B1;
      color: #fff;

      text-transform: uppercase;
      font-weight: bold;
      &:hover{
        color:#fff;
      }
    }
  }
}

#content-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 2;

  &.overflow-visible {
    overflow: visible;
  }

  .content-wrapper-actions {
    position: absolute;
    top: -40px;
    right: 0;
    padding: 0 20px
  }
}

.adn {
  height: 100%;
  width: 100%;
  margin: 10px 0 65px 0px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 7%;

    &__nav {
      width: 80%;

      padding: 0;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      list-style-type: none;

      &__elem {
        width: calc(25% - 10px);

        margin: 5px;

        box-shadow: 0 2px 4px rgb(180, 180, 180);

        background: transparent;

        &,
        &__button {
          border-radius: 5px;
        }

        &--active {
          box-shadow: 0 5px 8px rgb(180, 180, 180) !important;
        }

        &__button {
          width: 100%;

          padding: 8px 0;

          border: none;

          text-transform: uppercase;

          background-color: #fff;

          display: block;
          display: -webkit-box;

          line-height: 1.35;

          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__header {
      width: 80%;

      height: fit-content;

      display: flex;
      flex-direction: column;
      align-items: center;

      position: relative;

      margin-top: 50px;

      &__title,
      &__subtitle {
        box-shadow: 0 2px 4px rgb(180, 180, 180);

        background-color: #fff;
      }

      .ecologie, .viabilite-environnementale {
        color: #85be24;
      }

      .social, .inclusion-et-justice-sociale {
        color: #da8b2b;
      }

      .economie, .soutenabilite-economique {
        color: #5c6dac;
      }

      .gouvernance, .gouvernance-et-cooperations {
        color: #bd2783;
      }

      .sante-et-bien-tre-au-travail-et-cooperations {
        color: black;
      }

      &__title {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 0;
        padding: 5px 20px;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        font-family: din_condensedbold;
        font-size: 1.8rem;
        line-height: 1.5;

        &__percent {
          width: 80px;
          height: 80px;

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 50%;
          left: 95%;

          transform: translate(-50%, -50%);

          font-size: 1.4rem;

          color: #666;
          font-weight: bold;

          z-index: 12;
        }

        &__circle {
          font-size: 1.4rem;
          width: 80px;
          height: 80px;
          position: absolute;
          z-index: 2;
          //top: -.5rem;
          right: 0;
          border-radius: 50%;
          font-weight:normal;
          font-family: lato_medium;

          >div {
            background-color: white;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            margin: 12.5px;
            display: flex !important;
            justify-content: center;
            align-items: center;
          }

          &__ecologie {
            background-image: linear-gradient(90deg, #dadf2f, #85be24);
          }

          &__social {
            background-image: linear-gradient(90deg, #da8b2b, #fbfb3b);
          }

          &__economie {
            background-image: linear-gradient(90deg, #5c6dac, #9ac5e4);
          }

          &__gouvernance {
            background-image: linear-gradient(90deg, #bd2783, #da5f46);
          }

        }
      }

      &__subtitle {
        width: 98%;

        padding: 13px 18% 6px 18px;

        display: flex;
        justify-content: space-between;

        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.3em;

        & > span {
          color: #444;
          font-weight: bold;
        }
      }
      .pastilles-adn {
        position: absolute;
        width: 39%;
        height: 320px;
        border-radius: 50%;
        top: 10%;
        right: -31%;
        z-index: 1;
        background: url('../images/medias/adn-big-nb.png') no-repeat 60% bottom white;
        background-size: 41% auto;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px transparentize($black, 0.45);

        @include media-breakpoint-down(lg) {
          display: none !important;
        }

        div {
          width: 100%;
          background-repeat: no-repeat;
          background-position: 60% bottom;
          background-size: 41% auto;
        }

        div.ecologie, div.viabilite-environnementale {
          background-image: url('../images/medias/adn-ecologie.png');
        }

        div.economie, div.soutenabilite-economique {
          background-image: url('../images/medias/adn-economie.png');
        }

        div.social, div.inclusion-et-justice-sociale {
          background-image: url('../images/medias/adn-social.png');
        }

        div.gouvernance, div.gouvernance-et-cooperations {
          background-image: url('../images/medias/adn-gouvernance.png');
        }
      }
    }

    &__list {
      width: 80%;

      margin-top: 20px;

      padding: 0;

      list-style-type: none;

      box-shadow: 0 2px 5px #5a5a5a;

      background-color: #fff;

      z-index: 12;

      margin-bottom: 100px;

      &__elem {
        width: 100%;

        //padding: 0 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > * {
          margin: 7px auto;
        }

        &.row {
          margin: 0;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e8e6e6;
        }

        &__name {
          width:53%;
          font-size: 1.2em;
        }

        &__progress-bar {
          width: fit-content;

          display: flex;
          align-items: center;
          justify-content: center;

          &__bar {
            width: 200px;
            height: 15px;

            background-color: #ddd;

            &__filled {
              width: 0;
              height: 100%;

              &__ecologie {
                background-image: linear-gradient(90deg,#dadf2f,#85be24);
              }

              &__social {
                background-image: linear-gradient(90deg,#da8b2b,#fbfb3b);
              }

              &__economie {
                background-image: linear-gradient(90deg,#5c6dac,#9ac5e4);
              }

              &__gouvernance {
                background-image: linear-gradient(90deg,#bd2783,#da5f46);
              }
            }
          }

          &__percent {
            margin-left: -40px;
            font-size: 1.2em;
          }
        }

        &__search {
          width: 25px;
          height: 25px;

          cursor: pointer;
        }
      }
    }
  }

  &__image {
    & > i {
      width: 250px;
      height: 250px;
      margin-top: 20px;
      display: block;

      box-shadow: 0 2px 5px rgb(180, 180, 180);

      border-radius: 50%;

      background-clip: padding-box;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;

      background-color: #fff;

      transform: translate(-35%, 24%);

      z-index: 11;
    }
  }
}

@media (max-width: 900px) {
  .adn {
    margin: 100px 0;

    &__content {
      &__nav {
        flex-direction: column;

        &__elem {
          width: unset;
          &__button {
            width: 100%;
          }
        }
      }
    }

    &__image {
      display: none;
    }
  }

  .audience,
  .follow {
    margin-top: 50px;
  }
}

#click-menu {
  //max-width: 370px;
  width: 335px;
  z-index: 51;
}

#audience-chart,
#follow-chart {
  width: 100%;
  height: 100%;
}

.follow {
  &__card {
    cursor: pointer;
  }
}

.audience {
  &__month-button,
  &__year-button {
    cursor: pointer;
    text-align: center;
    color: #585757;
    box-shadow: 0 2px 5px #b5b5b5;

  }
}

.block {
  display: block;
}

#rounded-thing {
  width: 60vw;
  max-width: 800px;
  height: 60vw;
  max-height: 800px;

  position: absolute;

  border: 2px solid #00C7B1;
  border-radius: 50%;

  top: -10vw;
  left: 0;

  z-index: 1;
}

.welcome {
  margin-top: 5%;
  max-width: 750px;
  & > div > .col-md-6 > * {
    margin: 20px 0;
  }
}

.formulaire {
  margin-top: 50px;
}

.btn {
  border-radius: 0.5rem;

  &-success {
    background-color: green;
  }
}

.typeform-share {
  font-size: 16px;
  box-shadow: 0 2px 5px #b5b5b5;
  border-radius: .7em;
}

.welcome-dashboard {
  margin-top: 0px;
  text-align: center;
  h3{
    color: $green-hover;
    font-size: 32px;
    font-weight: 500;
  }
  p{
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.has-already-login-modal {
  h4 {
    color: var(--brand-color-primary);
  }
  .modal-body {
    padding: 2rem 1rem 1rem;
  }
}

.card-body {
  margin-top: 0px;
}

.follow__card {
  padding-left: 0;
  padding-right: 0;
  //margin-left: px;
}

@media (min-width: 1025px) {
  .adn {
    margin-left: -100px;
    &__image {
      margin-left: -20px;
    }
  }

}

/* Grand écran */
@media (min-width: 900px) {
  /*
         ELEMENT A CACHER
      */
  .menu-hamburger {
    display: none;
  }

  /**
        sidebar
     */
  .container-sidebar {
    width: 200px;
    background-color: red;
  }

  .nav-sidebar {
    flex: 0 0 300px;


    display: inline-block;
    vertical-align: top;
    min-height: 650px;

    &.retract {
      flex: 0 0 100px;
      ul li {
        a.nav-sidebar__link {
          .img {
            margin: auto;
            img {
              margin: 0;
            }
          }
          .menu-sidebar-color {
            display: none;
          }
        }
        .badge {
          display: none;
        }
      }
    }



    &.reseau {
      .menu-sidebar {
        padding-left: 2rem;
      }
    }
  }
  .menu-sidebar {
    margin: 0;
    list-style-type: none;
    text-align: left;
    color: #4e555b;

  }
  #content-wrapper {
    width: 100%;
    max-width: 1200px;
    min-height: 100%;
    margin: 0 auto;
    padding: 7rem 0;
    display: flex;
    flex-direction: column;

    .content-wrapper-actions {
      top: 3rem;
    }

    &.adn-background {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 120px;
        height: 100%;
        width: 113px;
        background-size: contain;
        background-repeat: repeat-y;
        background-image: url('../images/medias/adn-nb.png');
        background-position: left 10px;
        opacity: .3;
      }
    }
  }

  .sidebar-active {
    color: #00c7b1;
  }

  /*
    end sidebar
  */

  .container-second {
    flex: available;
  }

  .improve-box {
    border-left: 10px solid #1c7430;
  }

  .card-deck {
    display: inline-block;
    margin: 0;
  }

  .card-deck .card-style {
    display: block;
    border: 0;
    margin: 0;
  }
}
#content-wrapper {

  &.smaller-window{
    max-width: 930px;
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 750px) {
    &.smaller-window{
    padding-left: 20px;
    padding-right: 20px;
    }
  }
}

.fa-search {
  flex: 1 0 5%;
  color: #000;
  font-size: 1.2rem;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 2px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.cgu {
  font-family: 'lato_medium';
  margin-top:50px;
}




/* Styles backend Tommy */

.bg-light{
  background-color:#F9FAFC!important;
}

p{
  font-family: 'Montserrat', sans-serif;
}

h1{
  font-size: 22px;
}

h5{
  font-weight: 600;
}

body {
  font-family: 'Montserrat', sans-serif;

}

.titre_backend{
  font-family: 'Montserrat', sans-serif;
  // margin-top:100px;
  // text-align: center;
  font-weight: 100;
  color: black;

}


.titre-backend-suivi{
  font-family: 'Montserrat', sans-serif;
  margin-top:10px;
  text-align: left;
  font-weight: 100;
  font-size: 22px;
  color: black;
}

.titre_backend_bp{
  font-family: 'Montserrat', sans-serif;
  margin-bottom:50px;
  margin-left:20px;
  text-align: left;
  font-weight: 600;
  font-size: 25px;
  color: black;

}

.titre_backend_reseau{
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: left;
  font-weight: 300;
  font-size: 23px;
  color: black;
}

.upgrades__upgrade__button{
box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.01), 2px 2px 20px rgba(0, 0, 0, 0.01);
border-radius: 50px;
text-transform: none!important;
min-height:80%;
top:5px;
font-weight: 400;

}

.upgrades__upgrade__progress-bar__percent{
  font-weight: 100;
  margin-right: 20px;
}

.upgrades__upgrade__left{
  width: 100%;
  display: contents;
  background-color: #fff;
  box-shadow: $shadow-elisa;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
}

.upgrades__upgrade__right {
  margin-left: 10px;
  margin-right: 20px;
}

.upgrades__upgrade{
    box-shadow: none !important;
    border-left: 6px solid var(--brand-color-primary);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.upgrades__upgrade__progress-bar__bar{
  height: 8px;
  background-color: #F9FAFC!important;
  box-shadow: $shadow-elisa;
  border-radius: 50px;
  width:80%;
  margin-right: 5%;

}


.upgrades__upgrade__progress-bar__bar-suivi{
  height: 7px;
  background-color: #F9FAFC!important;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.8), 1px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 4px 20px rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  top: 5px;
    position: relative;
    width:100%;
    margin-right: 5%;
}

.upgrades__upgrade__progress-bar__bar__filled{
  border-radius: 50px;
  background-color:transparent!important;


}

.upgrades__upgrade__progress-bar{
  flex: 4;
  margin: 15px 5%;
}


.upgrades__upgrade__progress_bar__point__filled-suivi{
  border-radius: 10px;
  width:20px;
  height:20px;
}

.upgrades__upgrade {
  &__name {
    flex: 6;
    font-style: normal;
    font-weight: normal;
    font-size: 7px;
    line-height: 22px;
    /* or 314% */
    margin: 15px 0 15px 15px;
    letter-spacing: -0.015em;
    color: #000000;
    font-size: 15px;
  }
}


  .note-pourcent{
    color: rgba(0, 0, 0, 0.5);
    font-weight: 200;
    font-size: 1.2rem;
  }


  .titre-suivi{
    text-transform: none!important;
    min-width: 120px;
    color: black;
    flex: 1;
    padding-right: 10px;
  }

  .card-suivi{
    border:none;
    border-radius:20px;
    .card-body{
      padding: 0.9rem;
    }
    .card-suivi__body{
      border-top: 2px solid $lightest-grey-2;
      padding-left: 1rem;
      padding-right:1rem;
      padding-top: 0.25rem
    }

  }
  .card-suivi-2{
    border:none;
    border-radius:20px;

  }

  .nav-sidebar{
    background-color: transparent;
  }
  .nav-sidebar > ul > .is-active{
    box-shadow: none;
  }

  .bp-card-head{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding: 1rem 1.25rem;
  }

  .bp-titre {
    font-size: 18px;
  }

  .bp-card-header{
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }

  .bp-card{
    border-radius:5px!important;
    margin-bottom:30px;
    border:none;
  }

  .rotate-icon {
    width:20px;
  }

  .md-accordion .card .card-header a:not(.collapsed) .rotate-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  a:hover{
    color: $green-hover;
    text-decoration: underline;
  }
  .bp-ul{
    list-style: none;
    display: flex;
    counter-reset: my-awesome-counter;
    margin: 0;
    padding: 0;
    flex-direction: column;

  }

  .bp-ul li {

    counter-increment: my-awesome-counter;
    display: flex;
    margin-bottom: 0.5rem;
  }

  .bp-ul li::before {
    content: "0" counter(my-awesome-counter) ".";
    font-weight: bold;
    display: flex;
    font-size: 1rem;
    margin-right: 1rem;
    line-height: 1;
    text-align: right;
    color: $green-hover;
  }

  .bp-card-header > a > svg {
    color:black;
  }
  @media (min-width: 768px) {
    .bp-card {
      margin-left: 10px;
      width: 80% !important;
    }
  }

  /*Page réseau*/

  .tr-line{
    position: relative;
    padding: 9px 15px;
  }

  .tr-line:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 20%;
    height: 60%;
    border-left: 1px solid transparentize($color: #000000, $amount: 0.75);
  }
  .tr-reseau{
    margin-bottom: 20px;
    line-height:30px;
  }


  .card-body-reseau{
    padding:10px;
    font-weight: 200;

    .logo-reseau {
      min-height: 60px;
      height: 100%;
      overflow: hidden;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 60px;
      }
    }

    .category-name {
      font-size: 14px;
      /*.ecologie {
        color: #85be24;
      }

      .social {
        color: #da8b2b;
      }

      .economie {
        color: #5c6dac;
      }

      .gouvernance {
        color: #bd2783;
      }*/
      @media (max-width: 700px) {
        display:none;
      }
    }

    .adn-view {
      @media (max-width: 1100px) {
        flex: 0 0 100%;
        max-width: 100%;

        a {
          margin-left: 2rem;
          margin-right: 2rem;
          width: auto;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  i.facircle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
  }

  .btn-reseau{
    font-family: 'Montserrat', sans-serif;
    background: #FFFFFF;
    font-size:18px;
    padding-bottom:15px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 40px;
    border:none;
    font-weight:300;
    margin-left: 10px;
    // margin-bottom:20px;

    @media (max-width: 500px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .btn-reseau-2{
    font-family: 'Montserrat', sans-serif;
    border-radius: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid;
    font-size: 19px;

    &:hover, &:focus {
      box-shadow: none;
      color: #FFF;
      background: $green-hover;
      text-decoration: none;
    }
  }

  .btn-reseau-danger
  {
    color: $red-hover;
    border-color: $red-hover;

    &:hover, &:focus {
      box-shadow: none;
      color: #FFF;
      background: $red-hover;
      text-decoration: none;
    }
  }


  .upgrades__upgrade__progress_bar__point__filled{
    margin-top: 8px;
    margin-left: -12px;
    position: absolute;
    border-radius: 50%;
    width:8px;
    height:8px;
    background-color:$green-hover;

    &.ecologie, &.viabilite-environnementale, &.inclusion-et-justice-sociale, &.soutenabilite-economique, &.sante-et-bien-tre-au-travail, &.gouvernance-et-cooperations {
      background-color: #85be24;
    }

    &.social {
      background-color: #da8b2b;
    }

    &.economie {
      background-color: #5c6dac;
    }

    &.gouvernance {
      background-color: #bd2783;
    }
    &-network {
      top: 6px;
      right: 0;
      }
  }

  .reseau-resultat{
    p{
      font-weight:300;
      font-size:17px;
      margin-left: 10px;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
    :last-child{
      margin-bottom:50px;
    }
  }

  .sort-style{
    font-size: 1rem;
    margin-top: 10px;
  }

  .mtopbot{
    margin-top: 20px;margin-bottom:50px;
  }

  .mtop50{
    margin-top:50px;
  }

  .btn-reseau-spec{
    &:focus,
    &:hover {
      @extend .shadow-sm;
    }
  }

  .btn-reseau-spec-span{
    position: relative;
    top: 4px;
    font-size: 30px;
    padding-left: 8px;
    padding-right: 4px;
  }

  .network-home-searchbar, .network-home-searchbar-prepend {
    height: 50px;
  }
  .network-home-searchbar {
    margin-right: 24px;
    border-top-right-radius: 38px;
    border-bottom-right-radius: 38px;
    border: 1px solid var(--brand-color-primary);
  }
  .network-home-searchbar-prepend {
    .input-group-text {
      border-top-left-radius: 38px;
      border-bottom-left-radius: 38px;
      border: 1px solid var(--brand-color-primary);
      width: 56px;
      height: 50px;
      background-color: #fff;
      i {
        padding-left: 6px;
        color: var(--brand-color-primary);
      }
    }
  }

  .dropdown-date {
    &::after {
      display: none;
    }

    color: var(--brand-color-primary);
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
      // color: #000;
      @extend .shadow-sm;
    }
  }


  /*Sidebar réseau*/

  .menu-sidebar{
    font-family: 'Montserrat', sans-serif;
    font-weight:300;


  }
  #logout_sidebar_button {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 15px;
    width: 100%;
  }


  .red-dot {
    background-color: #FF4921;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    margin-left: 10px;
    border-radius: 50%;
  }

  .good-practices-container{
    .card-header{
      background-color:white;
    }
  }

  @media (max-width:1115px) {
    .upgrades__upgrade__name{
      width: 50%;
    }
    .upgrades__upgrade__progress-bar{
      justify-content: flex-end;
      width: 50%;
    }
    .upgrades__upgrade__progress-bar__bar{
      display:none;
    }
  }


.adn-evaluation-date {
  font-size: 0.85rem;
  text-align: end;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 10px;
  //color: var(--brand-color-primary);
}

.identification-form {
  .select2-required {
    display: none;
  }
  .select2-label {
    display: none;
  }
}


.custom-control{
  padding-left: 0em;
}

.custom-control-label {
  display: block;
  margin-left: 38px;
  color: $color-answers;
}

.custom-control .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control > .custom-control-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 16px;
  height: 16px;

  border: 2px solid #a1a1a1;
  margin-right: 5px;

  position: relative;
  top: 4px;
  float: left;
}

.custom-radio.custom-control > .custom-control-input {
  border-radius: 50%;
}

.custom-control > .custom-control-input:checked {
  border: 6px solid var(--brand-color-primary);
  transition: border-width 100ms linear;
}


.pwd-form-ctrl {
  // Bootstrap override
  &.is-invalid {
    background-image: none !important;
  }
  &.is-valid {
    background-image: none !important;
  }
}

.pwd-input-validation-bloc {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;

  &.opened {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }
}


.download-bp-enabled {
  color: inherit;
  cursor: pointer !important;
}

.download-bp-disabled {
  color: inherit;
  cursor: wait !important;
}
